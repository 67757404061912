


























import { Vue, Component, Prop } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import TypeErrorElem from "@/components/TypeErrorElem.vue";

@Component({ components: { TypeErrorElem, PageLayout, Header, Footer } })
export default class NotFound extends Vue {
  @Prop(String) private typeError!: string;
}
